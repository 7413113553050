@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?lw6ef8");
  src: url("fonts/icomoon.eot?lw6ef8#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?lw6ef8") format("truetype"), url("fonts/icomoon.woff?lw6ef8") format("woff"), url("fonts/icomoon.svg?lw6ef8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-chevron-left:before {
  content: "\e900"; }

.icon-chevron-right:before {
  content: "\e901"; }

.icon-chevron-thin-left:before {
  content: "\e902"; }

.icon-chevron-thin-right:before {
  content: "\e903"; }

.icon-phone:before {
  content: "\e942"; }

.icon-menu:before {
  content: "\e9bd"; }

.icon-cross:before {
  content: "\ea0f"; }

.icon-circle-down:before {
  content: "\ea43"; }

.icon-twitter:before {
  content: "\ea96"; }

.icon-linkedin:before {
  content: "\eaca"; }
